@font-face {
  font-family: 'GorditaBlack';
  src: url('./assets/fonts/Gordita Black.otf'); /* IE9 Compat Modes */
  font-style: normal;
}

@font-face {
  font-family: 'GorditaBlackItalic';
  src: url('./assets/fonts/Gordita Black Italic.otf'); /* IE9 Compat Modes */
  font-style: italic;
}

@font-face {
  font-family: 'GorditaBold';
  src: url('./assets/fonts/Gordita Bold.otf'); /* IE9 Compat Modes */
  font-style: normal;
}

@font-face {
  font-family: 'GorditaBoldItalic';
  src: url('./assets/fonts/Gordita Bold Italic.otf'); /* IE9 Compat Modes */
  font-style: italic;
}

@font-face {
  font-family: 'GorditaLight';
  src: url('./assets/fonts/Gordita Light.otf'); /* IE9 Compat Modes */
  font-style: bold;
}

@font-face {
  font-family: 'GorditaLightItalic';
  src: url('./assets/fonts/Gordita Light Italic.otf'); /* IE9 Compat Modes */
  font-style: italic;
}

@font-face {
  font-family: 'GorditaMedium';
  src: url('./assets/fonts/Gordita Medium.otf'); /* IE9 Compat Modes */
  font-style: normal;
}

@font-face {
  font-family: 'GorditaMediumItalic';
  src: url('./assets/fonts/Gordita Medium Italic.otf'); /* IE9 Compat Modes */
  font-style: italic;
}

@font-face {
  font-family: 'GorditaRegular';
  src: url('./assets/fonts/Gordita Regular.otf'); /* IE9 Compat Modes */
  font-style: normal;
}

@font-face {
  font-family: 'GorditaRegularItalic';
  src: url('./assets/fonts/Gordita Regular Italic.otf'); /* IE9 Compat Modes */
  font-style: italic;
}

@font-face {
  font-family: 'GorditaThin';
  src: url('./assets/fonts/Gordita Thin.otf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'GorditaThinItalic';
  src: url('./assets/fonts/Gordita Thin Italic.otf'); /* IE9 Compat Modes */
  font-style: italic;
}

@font-face {
  font-family: 'GorditaUltra';
  src: url('./assets/fonts/Gordita Ultra.otf'); /* IE9 Compat Modes */
  font-style: normal;
}

@font-face {
  font-family: 'GorditaUltraItalic';
  src: url('./assets/fonts/Gordita Ultra Italic.otf'); /* IE9 Compat Modes */
  font-style: italic;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F8F8F8 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
